import { FC } from "react";
import { AppBar, Hidden } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { ConnectButton } from "@dzambalaorg/provider-wagmi";
import { Localization } from "@dzambalaorg/provider-localization";
import { Theme } from "@dzambalaorg/provider-theme";

import { companyName, EnabledLanguages } from "@framework/constants";

import { DashboardButton } from "./dashboard";
import { Sections } from "./sections";
import { StyledGrow, StyledLink, StyledToolbar } from "./styled";

export const Header: FC = () => {
  return (
    <AppBar position="fixed">
      <StyledToolbar>
        <StyledLink component={RouterLink} to="/">
          <Hidden smDown>{companyName} - Admin panel</Hidden>
        </StyledLink>
        <StyledGrow />
        <Theme />
        <Localization languages={Object.values(EnabledLanguages)} />
        <ConnectButton />
        <DashboardButton />
        <Sections />
      </StyledToolbar>
    </AppBar>
  );
};
