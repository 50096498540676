import { FC } from "react";
import { Box } from "@mui/material";

import { TemplateAssetInput } from "@dzambalaorg/mui-inputs-asset";
import { EntityInput } from "@dzambalaorg/mui-inputs-entity";
import { NumberInput, TextInput } from "@dzambalaorg/mui-inputs-core";
import { RichTextEditor } from "@dzambalaorg/mui-inputs-draft";
import { AvatarInput } from "@dzambalaorg/mui-inputs-image-firebase";
import { ContractStatus, ModuleType, TokenType, VestingBoxStatus } from "@framework/types";

import { SelectInput } from "../../inputs/select";
import { PeriodDropdown } from "./period-dropdown";
import { Cliff } from "./cliff";
import { GrowthRate } from "./growth-rate";
import { ShapeDropdown } from "./shape-dropdown";
import { GridContainer, GridItem, Root } from "./styled";
import { useRenderPlot } from "./hooks";
import { DurationDropdown } from "./duration-dropdown";
import { CatDropdown } from "./cat-dropdown";

export interface IVestingBoxForm {
  id?: number;
}

export const VestingBoxForm: FC<IVestingBoxForm> = props => {
  const { id } = props;
  useRenderPlot();

  return (
    <Root>
      <GridContainer container>
        <GridItem item xs={5.7}>
          <AvatarInput name="imageUrl" required />
          <TextInput name="title" required />
          <EntityInput
            required
            name="template.contractId"
            controller="contracts"
            data={{
              contractModule: [ModuleType.VESTING],
              contractStatus: [ContractStatus.ACTIVE, ContractStatus.NEW],
            }}
            readOnly={!!id}
          />
          <TemplateAssetInput
            required
            autoSelect
            prefix="content"
            tokenType={{ disabledOptions: [TokenType.ERC721, TokenType.ERC998, TokenType.ERC1155] }}
            contract={{
              data: {
                contractType: [TokenType.ERC20],
                contractModule: [ModuleType.HIERARCHY],
                contractStatus: [ContractStatus.ACTIVE, ContractStatus.NEW],
              },
            }}
            forceAmount
            readOnly={!!id}
          />
          <TemplateAssetInput
            required
            autoSelect
            prefix="template.price"
            tokenType={{ disabledOptions: [TokenType.ERC721, TokenType.ERC998, TokenType.ERC1155] }}
            contract={{
              data: {
                contractType: [TokenType.ERC20],
                contractModule: [ModuleType.HIERARCHY],
                contractStatus: [ContractStatus.ACTIVE, ContractStatus.NEW],
              },
            }}
          />
          {id ? <SelectInput name="vestingBoxStatus" options={VestingBoxStatus} /> : null}
        </GridItem>
        <GridItem item xs={5.7}>
          <AvatarInput name="backgroundImg" />
          <Box id="function-plot" />
          <TextInput name="wallet" required />
          <ShapeDropdown />
          <DurationDropdown />
          <Cliff />
          <PeriodDropdown />
          <GrowthRate />
          <NumberInput name="template.cap" readOnly={!!id} required />
          <CatDropdown />
        </GridItem>
      </GridContainer>
      <RichTextEditor name="description" InputLabelProps={{ required: true }} />
    </Root>
  );
};
